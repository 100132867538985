<template>
	<div class="collect_mywzNews">
		<div class="collect_mywzNewsItem">
			<div class="textPart flex">
				<div class="imgPart">
					<router-link
						target="_blank"
						:to="{ name: '/gw_detail', params: { id: item.information.id } }"
					>
						<img
							class="mywzNews_pic pointer"
							:src="item.information.pic"
							:key="item.information.pic"
						/>
					</router-link>
				</div>

				<div class="collect_mainContent">
					<router-link
						target="_blank"
						:to="{ name: '/gw_detail', params: { id: item.information.id } }"
					>
						<div class="collect_mainContent_title pointer">
							{{ item.information.title }}
						</div>
					</router-link>
					<router-link
						target="_blank"
						:to="{ name: '/gw_detail', params: { id: item.information.id } }"
					>
						<div class="collect_mainContent_illustrate pointer">
							{{ item.information.illustrate }}
						</div>
					</router-link>
					<div class="collect_mainContent_timePart flex justify_content_sb">
						<div
							class="collect_mainContent_time"
							:class="{
								collect_mainContent_time_none:
									item.examine_type == 3 || item.examine_type == 4,
							}"
						>
							{{ item.information.release_time }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["item"],
	methods: {
		editItem() {
			this.$emit("editItem", this.item.id);
		},
		delItem() {
			this.$confirm("此操作将删除该条专栏, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$emit("delItem", this.item.id);
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					});
				});
		},
	},
};
</script>

<style scoped>
.collect_mywzNews {
}
.collect_mywzNewsItem {
	display: flex;
	justify-content: space-between;
	padding-bottom: 10px;
	padding-top: 10px;
}
.textPart {
	width: 100%;
}
.mywzNews_pic {
	width: 160px;
	height: 90px;
	object-fit: cover;
	border-radius: 18px;
}
.collect_mainContent {
	margin-left: 40px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100px;
}
.collect_mainContent_title {
	width: 100%;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
	text-transform: capitalize;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	/* position: absolute; */
	top: 0px;
}
.collect_mainContent_illustrate {
	font-size: 14px;
	font-weight: normal;
	line-height: 20px;
	text-transform: capitalize;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 描边颜色 */
	color: rgba(225, 227, 241, 0.7);
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.collect_mainContent_title:hover {
	color: #0056ff;
}
.collect_mainContent_timePart {
	bottom: 0px;
}
.collect_mainContent_edit div {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #999999;
	width: 90px;
	display: flex;
	justify-content: right;
}
.collect_mainContent_time {
	font-size: 14px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #8e8e8e;
}
.collect_mainContent_time_none {
	width: 400px;
}
.collect_mainContent_status {
	margin-top: 10px;
	margin-bottom: 6px;
	padding-bottom: 6px;
	align-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 20px;
	border-radius: 3px;
	/* 主色渐变 */
	background: linear-gradient(
		82deg,
		#9945ff 0%,
		#8752f3 24%,
		#5497d5 46%,
		#43b4ca 56%,
		#28e0b9 69%,
		#19fb9b 96%
	);
}
.collect_mainContent_status img {
	width: 15px;
	height: 15px;
}
.collect_mainContent_status span {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	margin-left: 8px;
	color: #ffffff;
}
</style>
<style scoped>
@media screen and (max-width: 900px) {
	.collect_mainContent {
		margin-left: 20px;
	}
	.collect_mainContent_title {
		-webkit-line-clamp: 2;
	}

	.collect_mainContent_illustrate {
		-webkit-line-clamp: 1;
	}
}
</style>
